































































































































































































































































































































































































































































































































































































































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    min-height: 600px;
    .content {
      max-width: 900px;
      .contentLoaded {
        .body {
          .filters {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            div {
              margin-right: 10px;
            }
            .selectRegions {
              max-width: 300px;
            }
            .selectHubs {
              max-width: 300px;
            }
            .searchPickContent {
              width: 100%;
            }
            margin-bottom: 25px;
          }


          .date {
            max-width: 230px;
          }

          .chartMonth {
            height: 300px;
            width: 300px;
            position: relative;
          }


          .orderTable {
            max-height: 500px;
            .orderRow {
              cursor: pointer;
            }
          }

          .myTable {
            max-width: 400px;
            h3 {
              margin-top:50px;
            }
            .bloco {
              border: solid 1px #bfb5bb;
             // margin-top: 10px;
              padding: 6px;
            }
            .linha {
              display: flex;
              justify-content: space-between;
              .c1 {

              }
              .c2{
                width: 120px;
                text-align: end;
              }
              .t0 {
                margin-left: 0px;
              }
              .t1 {
                margin-left: 0px;
              }
              .t2 {
                margin-left: 25px;
              }

            }
            .linhaM {
            //  margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
