























































































































































































































































































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    .content {
      .contentLoaded {
        .imagePreview {
          width: 281px;
          height: 161px;
          img {
          }
        }
        .body {

        }
      }
    }
  }
}
