






















































































@import '@design';

.cursor {
  cursor: pointer;
  color: blue;
}


