


























































































































































































































































@import '@design';

.box {
  background-color: white;
  .content {
    padding: 10px 20px 30px 10px;
    position: relative;
    .head {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      table {
        border-collapse: collapse;
        text-align: left;
        td {
          border: 1px solid #e7ecf1;
          padding: 8px;
        }
      }
    }

    .closeButton {
      color: #bb2f2c;
      cursor: pointer;
      top: 9px;
      left: 9px;
      position: absolute;
      i {
        color: #bb2f2c;
      }
    }

    .orderRow {
      cursor: pointer;
    }
  }
}
