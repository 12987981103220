// GRID
$size-grid-padding: 1.3rem;

// CONTENT
$size-content-width-max: 65rem;
$size-content-width-min: 14rem;

// INPUTS
$size-input-padding-vertical: 0.75em;
$size-input-padding-horizontal: 1em;
$size-input-padding: $size-input-padding-vertical $size-input-padding-horizontal;
$size-input-border: 1px;
$size-input-border-radius: (1em + $size-input-padding-vertical * 2) / 10;

// BUTTONS
$size-button-padding-vertical: $size-grid-padding / 2;
$size-button-padding-horizontal: $size-grid-padding / 1.5;
$size-button-padding: $size-button-padding-vertical
  $size-button-padding-horizontal;



$size-company-sidebar: 250;
$size-company-order-sidebar: 250;
$size-admin-sidebar: 290;
$size-client-sidebar: 215;

$size-company-menu-max: 1050;
$size-company-order-menu-max: 1400;
$size-admin-menu-max: 1050;
$size-client-menu-max: 1050;

$size-menu-actions: 700;

$size-ong-menu-max: 1050;
$size-ong-sidebar: 250;
$size-ong-menu-max: 1050;


$size-nav-bar-width-max: 1382;
