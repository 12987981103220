













































































































































































































































































































































































































































































































 @import '@design';

 .boxItem {
   @media screen and (max-width: 950px) {
     display: none;
   }
 }

 .box {
 /*  background-color: #7691c2;*/

   flex: 1 2 0;

   max-width: 450px;
/*   min-width: 450px; */
   margin-bottom: 20px;

   @media screen and (max-width: 1100px) {
     min-width: unset;
   }

   .head {
     padding: 15px;
     background-color: $color-submenu-bg;
   }
   .firstLine {

     display: flex;
     align-items: center;

     .add {
       padding: 15px;
       cursor: pointer;
     }

   }
   .sizesLine {
     color: white;

   }

   .listView {
     .transitionGroup {
       display: flex;
       flex-direction: column;
       overflow: scroll;
       // scroll-snap-type: y mandatory;

       height: 100%;
       //  max-height: 100%;
       max-height: 1000px;
     }
   }


   .itemSelected {
     background-color: #bedbe4;
   }

   .item {
     cursor: pointer;
     user-select: none;
     align-items: flex-start;
     text-align: left;
     padding-left: 20px;
     position: relative;
     padding-top: 11px;
     padding-bottom: 11px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     flex: 1 0 auto;
     /* box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12); */
     border-bottom: solid 1px rgba(0, 0, 0, 0.12);


     .info {
       margin-right: 7px;
       width: 100%;
       .icon {
         margin-right: 10px;
         font-size: 18px !important;
       }

       .status {
         display: flex;
         justify-content: space-between;
         .left {
           margin-right: 15px;
           .bullet {
             height: 15px;
             width: 15px;
             display: inline-block;
             border-radius: 15px;
             margin-right: 10px;
             margin-left: 5px;
           }


         }
         .right {

         }
         .itemPending {
           color: red;
         }
       }

       .statusPayment {
         vertical-align: baseline;
         border-radius: .25em;
         display: inline;
         /*color: #fff;*/
         padding: 2px;
       }
       p {
         margin-top: 1px;
         margin-bottom: 1px;
       }

       .nextStatus {
         display: flex;
         align-items: center;
         justify-content: flex-end;
       }
     }
   }

   .noOrder {
     padding: 40px;
     font-size: large;
   }

   .imgUrl {
     max-width: 110px;
     max-height: 110px;
   }
 }


