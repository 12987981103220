


























































































































































































































































































































































































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    min-height: 500px;
    .content {
      max-width: 400px;
      .contentLoaded {

        .image {
          @extend %defaultPreview;
          position: relative;
          width: #{$previewPromotionW*$previewPromotionScale}px;
          height: #{$previewPromotionH*$previewPromotionScale}px;
          cursor: pointer;
          margin-bottom: 10px;

          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          img {
            width: 100%;
            height: 100%;
            margin-bottom: 20px;

            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
          .changeImage {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            margin-top: #{$previewPromotionH*$previewPromotionScale*0.6}px;
            color: white;
            background-color: rgba(0, 0, 0, 0.71);
            text-align: center;
            display: none;

            span {
              align-self: center;
              margin: 0 auto;
            }
          }

          .previewLine1 {
            background-color: var(--primary-color);
            color: var(--secondary-color);

            font-family: $font-primary-text;
            font-size: #{16*$previewPromotionScale}px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;


            top: #{66*$previewPromotionScale}px;
            text-align: left;
            position: absolute;
            width: #{120*$previewPromotionScale}px;

            height: #{21*$previewPromotionScale}px;

            padding-left: #{4*$previewPromotionScale}px;
            padding-right: #{4*$previewPromotionScale}px;

            div {
              display: flex;
              width: 100%;
              height: 100%;
              span {
                align-self: center;
                white-space: nowrap;
              }
            }

          }

          .previewLine2 {
            background-color: var(--secondary-color);
            color: var(--primary-color);

            font-family: Montserrat-ExtraBold $font-primary-text;
            font-size: #{9*$previewPromotionScale}px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            display: flex;
            align-items: center;
            text-align: left;

            top: #{86*$previewPromotionScale}px;
            position: absolute;
            width: #{147*$previewPromotionScale}px;
            min-height: #{25*$previewPromotionScale}px;

            padding-left: #{4*$previewPromotionScale}px;
            padding-right: #{4*$previewPromotionScale}px;

            div {
              display: flex;
              width: 100%;
              height: 100%;
              span {
                align-self: center;
              }
            }
          }
        }
        .image:hover .changeImage {
          display: flex;
        }


        .imagePreview {
          width: #{$previewCategoryW*$previewCategoryScale}px;
          height: #{$previewCategoryH*$previewCategoryScale}px;
          border-radius: 300px;
          img {
            border-radius: 300px;
          }
        }
        .body {

        }
      }
    }
  }
}
