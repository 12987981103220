











































@import '@design';

.box {
  display: flex;
  overflow-y: scroll;

  > div {
    flex: 1 1 0px;
    position: relative;
  }
}



