

























































































































































































































































































































// @import '@design';

.inputImage {
  display: none;
}

.modal {
  // padding-left: 20px;
  // padding-right: 20px;

  > div {
    @media screen and (max-height: 700px) {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      transform: none;
    }
  }
}
.box {
  background-color: white;
  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .cropper {
      position: relative;
      min-width: 200px;
      max-width: 700px;
      .actionsIcons {
        display: block;
        position: absolute;
        top:10px;
        right: 10px;
        div {
          display: inline;
          .actionsIconDone {
            color: green;
            font-size: 32px!important;
            cursor: pointer;
          }
          .actionsIconCancel {
            margin-left: 10px;
            font-size: 27px!important;
            cursor: pointer;
          }
        }
      }
    }

    .edit {
      margin-top: 10px;
    }
  }
}


.separator {
  margin-left: 18px;
}




.button {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}


