


































































































































































































































































































































































































































































































































































































































































































































































































































@import '@design';


.orderEdit {
  width: 45px;
  display: inline-flex;
  margin-right: 15px;
}

.orderEdit span {
  margin-left: 0!important;
}

.orderEdit input{
  width: 45px!important;
}

.nextStatusTd {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.nextStatusTd span{
  display: block;
}

.nextStatusTd > :nth-child(2){
  margin-left: 20px;
}

.buttonCancell {
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.box {
  @extend %defaultBoxF1;

  flex: 2 1 0;
  .container {
    min-height: 530px;

    .disableShadow {
      box-shadow: none!important;
      max-width: 1500px!important;
    }
    .content {
      min-width: unset;
      max-width: 1000px;

      @media screen and (max-width: 820px) {
        max-width: unset;
      }
      padding: 20px;
      position: relative;
      .contentLoaded {
        .imagePreview {
          width: #{$previewCategoryW*$previewCategoryScale}px;
          height: #{$previewCategoryH*$previewCategoryScale}px;
          border-radius: 300px;
          img {
            border-radius: 300px;
          }
        }
        .body {
          font-family: $font-input;
          .client {
            cursor: pointer;
          }
          .closeButton {
            color: #bb2f2c;
            cursor: pointer;
            top: 9px;
            left: 9px;
            position: absolute;

            i {
              color: #bb2f2c;
            }
          }

          .orderInfo span {
            margin-left: 18px;
          }

          .sectionTitle {
            //  display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .section {
            margin-bottom: 30px;
          }
          .orderItem {
            max-width: 450px;
            .orderItemName {
              cursor: pointer;
              display: block;
              font-weight: bold;
            }
            .orderItemDescription {
              font-size: small;
              margin-left: 0px;
              display: block;
            }

            .orderItemNameFlavor {
              cursor: pointer;
            }

            .orderItemAdditionalContentFlavor {
              margin-top: 2px;
              display: block;
              /* margin-left: 5px; */
              span {
                font-weight: bold;
                display: block;
                margin-left: 5px;
              }
              :first-child {
                font-weight: normal;
                margin-left: 0px;
              }
            }
            .orderItemAdditional {
              cursor: pointer;
              .orderItemAdditionalContent {
                margin-top: 2px;
                display: block;
                margin-left: 5px;
                span {
                  font-weight: bold;
                  display: block;
                  margin-left: 5px;
                }
                :first-child {
                  font-weight: normal;
                  margin-left: 0px;
                }
              }
            }

            .orderItemIngredientsRestrictions {
              margin-top: 2px;
              display: block;
              margin-left: 5px;
              span {
                font-weight: bold;
                display: block;
                margin-left: 5px;
              }
              :first-child {
                font-weight: normal;
                margin-left: 0px;
              }
            }

          }

          .statusPayment {
            vertical-align: baseline;
            border-radius: .25em;
            display: inline;
            /*color: #fff;*/
            padding: 2px;
          }

          .orderStatus {
            ul {
              display: flex;
              //  margin-left: 10px;
              //  margin-right: 10px;
              padding-right: 25px;

              padding-left: 0;

              @media screen and (max-width: 500px) {
                flex-flow: row wrap;
              }


              .liFirst {
                width: 100px;
              }
              li {
                display: table-cell;
                position: relative;
                width: 100%;
                @media screen and (max-width: 500px) {
                  width: 100px;
                }

                cursor: pointer;
                .lineContent {
                  display: block;
                  position: absolute;
                  height: 3px;
                  width: 100%;
                  top: 20px;
                  padding-right: 43px;
                  .line {
                    height: 3px;
                    width: 100%;
                    background-color: #a4a4a4;
                    box-shadow: 2px 1px 3px -1px rgba(0, 0, 0, 0.4), 1px 3px 7px 0 rgba(0, 0, 0, 0.3), 5px 5px 12px 0px rgba(0, 0, 0, 0.3);
                  }
                }
                .button {
                  float: right;
                  a {
                    border-radius: 25px;
                    height: 43px;
                    width: 43px;
                    border: 2px solid #a4a4a4;
                    display: block;
                    text-align: center;
                    background-color: #fff;
                    color: #a4a4a4;
                    float: right;
                    margin-left: 100%;
                    padding: 10px 15px;
                    box-shadow: 5px 3px 4px -1px rgba(0, 0, 0, 0.2), 6px 6px 7px 0 rgba(0, 0, 0, 0.14), 5px 5px 12px 0 rgba(0, 0, 0, 0.12);
                  }
                  span {
                    display:block;
                    // font-size: 12px;
                    text-align: center;
                    width: 50px;
                    margin-right: -30px;
                  }
                  .statusName {
                    margin-top: 54px;
                  }
                  .statusTime {
                    margin-top: 7px;
                  }
                }
              }
              .active a b {
                color: #ffffff;
              }
              .active, .active a {
                cursor: initial;
              }
            }
          }

          .orderItems {

            table, th, td {
              border: 1px solid #e7ecf1;
              padding: 8px;
            }
            table {
              border-collapse: collapse;
            }

            thead {
              font-size: 18px;
              font-weight: bold;
            }
            tbody {
              background-color: #FBFCFD;
            }


          }
        }
      }
    }
  }
}
