

%contentHeaderTitle {
  @extend %fontContentHeaderTitle;
  margin-bottom: 20px;
}

%defaultPreview {
  box-shadow: 2px 2px 9px 0px #8888884f;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .changeImage {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin-top: 42%;
    color: white;
    background-color: rgba(0, 0, 0, 0.71);
    text-align: center;
    display: none;

    span {
      align-self: center;
      margin: 0 auto;
    }
  }
  .removeImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.71);
    display: none;
    align-items: center;
    justify-content: center;
  }

  &:hover .changeImage {
    display: flex;
  }
  &:hover .removeImage {
    display: flex;
  }

}


%defaultBoxF1 {
  background-color: #ffffff;

  .container {
    margin: 10px 8px 30px 8px;
    min-height: 600px;

    .content {
      box-shadow: 2px 2px 9px 0px #8888884f;
      margin: 0 auto;
      min-width: 600px;
      max-width: 1000px;
      @media screen and (max-width: 1000px) {
        max-width: 100%;
      }
      @media screen and (max-width: 600px) {
        min-width: 100%;
      }
      margin: 0 auto;
      text-align: left;
      padding: 49px;
      // background-color: $color-form-bg;

      .contentLoaded {
        .contentHeaderTitle {
          @extend %contentHeaderTitle;
        }
        .imagePreview {
          @extend %defaultPreview;
        }
        .fcmEditor {
          border: solid 1px rgba(0, 0, 0, 0.08);
        }
        .body {
          font-family: $font-input;
        }
        .error {
          margin-top: 20px;
          margin-bottom: 20px;
          color: $color-input-error;
        }
        .progressBar {
          margin-bottom: 20px;
        }
        .actions {
          margin-top: 35px;
          padding-bottom: 25px;
          .leftButton {
            font-family: $font-primary-text;
            background-color: #d46f6d;
            float: left;
          }
          .leftButton[disabled] {
            background-color: #0000001f;
          }
          .rightButton {
            font-family: $font-primary-text;
            float: right;
          }
        }
      }
    }
  }
}

%defaultAlert {
/*  color: white;
  background-color: #da312e80; */

  color: #da312e;
  background-color: white;

  //border: solid 2px #da312ecc;
  z-index: 1000;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
}
