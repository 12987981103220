



































































































































































































@import '@design';
.error {
  color: $color-input-error;
}
.container {
  min-width: $size-content-width-min;
  max-width: $size-content-width-max;
  margin: 0 auto;
  display: flex;
  min-height: 600px;
  padding-top: 80px;
  padding-bottom: 30px;
  .content {
    width: 90%;
    padding: 49px;
    text-align: left;
    background-color: $color-form-bg;
    margin: 0 auto;
    max-width: 400px;
   // max-height: 550px;
    .title {
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: -0.4px;
      margin-bottom: 10px;
    }
    .body {
      font-family: $font-input;
      .loginButton {
        margin-bottom: 5px;
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}


