













































































































































































































































































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    min-height: 600px;
    .content {
      max-width: 900px;
      .contentLoaded {
        .imagePreview {
          width: #{$previewCategoryW*$previewCategoryScale}px;
          height: #{$previewCategoryH*$previewCategoryScale}px;
          border-radius: 300px;
          img {
            border-radius: 300px;
          }
        }
        .body {
          .schedulesScaleTitle {
            display: inline-block;
          }
          .schedulesScale {
            display: inline-block;
          }
          .schedulesItens {
            width: 100px;
            display: inline-block;
            margin-right: 20px;
          }

          .schedulesItensTitle {
            width: 100px;
            display: inline-block;
            margin-right: 30px;
          }
        }
      }
    }
  }
}
