






























































































@import '@design';

.error {
  color: $color-input-error;
}
.container {
  min-width: $size-content-width-min;
  max-width: $size-content-width-max;
  margin: 0 auto;
  display: flex;
  min-height: 300px;
  padding-bottom: 30px;
  .content {
    width: 100%;
    padding: 25px 10px 15px;
    text-align: left;

    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    justify-content: space-around;
    .blogItem {
      margin-top: 30px;
      cursor: pointer;

      flex: 1 1 400px;
      max-width: 412px;
      margin-left: 16px;
      margin-right: 16px;
      text-align: left;

      @media screen and (max-width: 500px) {
        margin-left: 0;
        margin-right: 0;
      }

      .image {
        max-width: 100%;
      }
      .image img {
        border-radius: 8px;
      }

      .title {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
    }
  }
}


