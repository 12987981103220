











































































































































































































































































































































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    min-height: 600px;
    .content {
      max-width: 980px;
      padding: 20px;
      .contentLoaded {
        .imagePreview {
          width: #{$previewCategoryW*$previewCategoryScale}px;
          height: #{$previewCategoryH*$previewCategoryScale}px;
          border-radius: 300px;
          img {
            border-radius: 300px;
          }
        }
        .body {
          .roundAt {
            width: 200px;
          }
          .restrictionBox {
            display: flex;
            .formItemText {
              margin-left: 10px;
              flex: 1;
            }
          }

          .schedulesItens {
            width: 100px;
            display: inline-block;
            margin-right: 30px;
          }

          .schedulesItensTitle {
            width: 100px;
            display: inline-block;
            margin-right: 30px;
          }

          .infoBox {
            text-align: center;
            .infoText {
              font-size: 19px;
              padding: 10px;
            }
            .infoButton {
              padding: 10px;
            }
          }
          .map {
            width: 100%; height: 700px
          }

          .areasContent {
            margin-top: 20px;
            .deleteArea {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
