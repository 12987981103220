






















































@import '@design';
.box {
  width: 100%;
  background-color: $color-bg-mid;
  .container {
   // min-width: $size-content-width-min;
    max-width: 1052px;
    margin: 0 auto;
    display: flex;
    min-height: 672px;
    padding: 0;
    flex-direction: row;
    justify-content: space-around;

    @media screen and (max-width: 980px) {
      flex-direction: column;
      padding-top: 28px; // TODO
    }
    .contentLeft {
      padding-right: 16px;
      padding-left: 16px;

      .bodyLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .eatContainer {
         // position: relative;
          width: 545px;
          @media screen and (max-width: 650px) {
            width: 90%;
          }
          .eatBg {
           // position: absolute;
            width: 100%;
            object-fit: contain;
          }
          .eatTxt {
         //   position: absolute;
         //   top: 0;
         //   left: 0;
            width: 545px;
            height: 264px;
          }
        }
        .orderedDifferent {
          margin-top: 10px;
          max-width: 352px;
          text-align: center;
          font-family: OpenSans, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: -0.8px;
          color: white;
        }
        .download {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;

          .downloadPlayStore, .downloadAppleStore {
            width: 120px;
            height: 40px;
            object-fit: contain;
            cursor: pointer;
            margin: 10px;
          }
        }
      }
    }

    .contentRight {
      padding-right: 16px;
      padding-left: 16px;
      padding-top: 35px;

      .bodyRight {
        .featuredImage {
          max-height: 672px;
          object-fit: contain;
        }
      }

    }
    /*
    @include typography-interpolate(
            'font-size',
            300px,
            30px,
            1100px,
            64px
        );

     */
  }
}
