



























































































@import '@design';

.localeChanger {
  width: 36px;
  .localeField {
    padding-top: 24px;
  }
}
.flag {
  width: 30px;
  display: inline;
  max-height: 28px;
  cursor: pointer;
}

.flagSpan span{
  justify-content: space-between;
  flex-direction: row;
}

