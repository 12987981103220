


































































































































































































































































































































































































































@import '@design';
.box {
  @extend %defaultBoxF1;
  .container {
    .content {
      .contentLoaded {
        .imagePreview {
          @extend %defaultPreview;
          width: #{$previewProfileW*$previewProfileScale}px;
          height: #{$previewProfileH*$previewProfileScale}px;

          border-radius: 300px;
          img {
            height: 100px;
            width: 100px;
            margin-bottom: 20px;
            border-radius: 300px;
          }
        }
        .body {

        }
      }
    }
  }
}
