























































































































































































































































































































































































































































@import '@design';
.box {
  max-width: 480px;
  min-width: 480px;

  @media screen and (max-width: 480px) {
    min-width: 100%!important;
  }

  .head {
    padding: 15px;
    background-color: $color-submenu-bg;
  }
  .firstLine {
    display: flex;
    align-items: center;
    .add {
      padding: 15px;
      cursor: pointer;
    }
  }
  .body {
    .listView {
      background-color: #7691c214;
      display: flex;
      flex-direction: column;
      overflow: scroll;
     // scroll-snap-type: y mandatory;

      height: 100%;
    //  max-height: 100%;
      max-height: 1000px;

      .imgUrl {
        max-width: 110px;
        max-height: 70px;
      }

      .itemSelected {
        background-color: #bedbe4;
      }
      .hasError {
        background-color: #ef0f1838;
      }

      .item {
        cursor: pointer;
        user-select: none;
        position: relative;
        padding-left: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        flex: 1 0 auto;
        /*box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);*/
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
        .formItemImg {
          display: flex;
          align-items: center;
        }

        .itemDesc {
          flex: 10;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .itemHead {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.87);
            padding-left: 8px;
            padding-right: 8px;
          }
          .itemSub {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.54);
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        .itemAction {
          flex: 1;
          align-self: center;
          .md-radio, .md-switch {
            margin: 7px 16px 7px 0;
          }
          .itemControl {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
          }
        }

        .itemAction .md-checked + .itemControl  {
          background-color: rgba(0, 0, 0, 0.05);
        }

        .itemHelp {
          color: rgba(0, 0, 0, 0.12);
          align-self: center;
        }



      }

    }
  }

}


