




















































@import '@design';
.box {
  width: 100%;
  background-color: $color-bg-mid;
  color: #000000;

  padding: 96px 8px 72px 8px;

  @media screen and (max-width: 980px) {
    padding: 29px 8px 20px 8px;
  }

  .container {
    max-width: 1052px;
    height: 269px;
    margin: 0 auto;
  //  min-height: 672px;
    background-color: white;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .contentLeft {
      padding-right: 16px;
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-grow: 1;
      width: 50%;

      @media screen and (max-width: 980px) {
        align-items: center;
      }

      .bodyLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-width: 352px;

        .flexibilityTxt {
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          width: 100%;

          @media screen and (max-width: 980px) {
            text-align: center;
          }
        }

        .flexibilityTxt {
          font-family: $font-montserrat;
          font-size: 32px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -0.5px;
          color: #293343;
          @include typography-interpolate(
              'font-size',
              250px,
              19px,
              500px,
              32px
          );
        }

        .download {
          width: 100%;
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          @media screen and (max-width: 350px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .downloadPlayStore, .downloadAppleStore {
            width: 120px;
            height: 40px;
            object-fit: contain;
            cursor: pointer;
            margin: 10px 20px 10px 0;

          }
        }

        .orderedDifferent {
          margin-top: 10px;
          max-width: 352px;
          text-align: center;
          font-family: OpenSans, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: -0.8px;
          color: white;
        }
      }
    }
    .contentRight {
      width: 50%;
      @media screen and (max-width: 980px) {
        display: none;
      }
      padding-right: 16px;
      flex-grow: 1;

      .bodyRight {
        width: max-content;
        .featuredImage {
          margin-top: -30px;
          max-height: 299px;
          object-fit: contain;
        }
      }
    }
  }
}
