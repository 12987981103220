


























































@import '@design';

.isfake {
  position: relative!important;
  bottom: unset!important;
  //background-color: rgba(13, 51, 73, 0.32)!important;
  visibility: hidden;
}

.box {
  width: 100%;
  background-color: $color-footer-bg;
  padding: 10px;
  margin: 0;
/*  position: absolute;
  bottom: 0;*/
  .container {
    padding: 0;
    text-align: center;
    list-style-type: none;
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    .head {
      padding-top: 28px;
      padding-bottom: 22px;
      border-bottom: solid 1px $color-footer-hr;
      img {
        float: left;
        height: 36px;
      }
      a {
        float: right;
        margin-left: 16px;
        height: 36px;
      }
      .logo {
        height: 86px;
      }

    }

    .body {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      div {
        padding-bottom: 5px;
        padding-top: 5px;
      }
      a, a:link, a:visited, a:hover, a:active {
        color: $color-footer-link;
        text-decoration: none;
      }

    }

    .footer {
      padding-bottom: 9px;
      padding-top: 19px;
      border-top: solid 1px $color-footer-hr;
      .copyright {
        padding-top: 10px;
        float: left;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-footer-copyright;
      }
      a {
        float: right;
        margin-left: 16px;
        img {
          height: 40px;
        }
      }
    }
  }
}




