












































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    .content {
      .contentLoaded {
        .imagePreview {
          width: #{$previewCategoryW*$previewCategoryScale}px;
          height: #{$previewCategoryH*$previewCategoryScale}px;
          border-radius: 300px;
          img {
            border-radius: 300px;
          }
        }
        .body {
          pre {
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}
