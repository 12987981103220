































































































































































































































































































































































@import '@design';

.searchPick {
  max-width: 400px;
}

.box {
  text-align: initial;
  margin-left: 10px;
}

.date {
  max-width: 230px;
}

.button {
  max-width: 450px;
}

