




































































@import '@design';
html {
  background-color: white!important;
}

.box {
  width: 100%;
  min-height: 100%;
  position: relative;
  background-color: $color-ong-bg;

  .outside {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9;
  }

  .sidebarDesktop {
    display: block;
    top: 118px;
    position: absolute;
    z-index: 10;
    /* top: 118px; */
    left: 0;
    /* bottom: 0; */

    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.05), 0 4px 20px 0px rgba(0, 0, 0, 0.15);

    @media screen and (max-width: $size-ong-menu-max+px) {
      display: none;
    }
    width: $size-ong-sidebar+px;
  }

  .sidebarMobile {
   /* top: 190px; */
    background-color: white;
    position: absolute;
    z-index: 10;
    left: 0;

    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.05), 0 4px 20px 0px rgba(0, 0, 0, 0.15);
  }

  .sidebarMenuButton {
    display: none;
    position: absolute;
    z-index: 11;
    top: 66px;
    left: 0;
    @media screen and (max-width: $size-ong-menu-max+px) {
      display: block;
    }
  }

  .container {
    /* display: flex; */
    width: 100%;
    .menu {
      /*
      flex: 1;
      align-self: baseline; */
      margin: 0 auto;
    }

    .content {
      /*
      flex: 1;
      align-self: center; */
      margin: 0 auto;
      position: relative;
    }
    .withSidebar {
      margin-left: $size-ong-sidebar+px;
      @media screen and (max-width: $size-ong-menu-max+px) {
        margin-left: 0px;
      }
    }
  }
}


