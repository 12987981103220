



















































































































































































































































































































































































@import '@design';

.fcmContent {
  height: 200px;
  overflow: scroll;
}

.fcmItem {
  height: 80px;
  border: #7691c2 1px solid;
}


.error {
  color: $color-input-error;
}

.container {
  min-width: $size-content-width-min;
  max-width: $size-content-width-max;
  margin: 0 auto;
  display: flex;
  min-height: 600px;
  padding-top: 80px;
  padding-bottom: 30px;
  .content {
    width: 90%;
    padding: 49px;
    text-align: left;
    background-color: $color-form-bg;
    margin: 0 auto;
    max-width: 1550px;
    .cadastreSeuRestaurante {
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: -0.4px;
      margin-bottom: 10px;
    }
    .jaPossuiConta {
      font-family: $font-secondary-text;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #9b9b9b;

    }
    .criaUmaNovaConta {
      font-family: $font-secondary-text;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $color-primary;
    }
    .body {
      font-family: $font-input;
      .fullwidth {
        width: 100%;
      }
      BaseButton {
        font-family: $font-primary-text;
      }
    }
  }
}


