






















































































































































































































































































































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    .content {
      max-width: 800px;
      .contentLoaded {
        .imagePreview {
          width: #{$previewCategoryW*$previewCategoryScale}px;
          height: #{$previewCategoryH*$previewCategoryScale}px;
          border-radius: 300px;
          img {
            border-radius: 300px;
          }
        }
        .body {
          .restaurantName {
            font-size: 32px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #333333;
            margin-bottom: 20px;
          }

          .progressBar {
            margin-bottom: 20px;
          }

          .addressItem {
            margin-left: 12px;
          }

          .addressItemLock {
            margin-left: 12px;

          }
          .addressNumberComplement {
            display: flex;
            .addressNumber {
              flex: 2;
              width: 60px;
            }
            .addressComplement {
              flex: 8;
            }
          }
          .addressCityState {
            display: flex;
            .addressCity {
              flex: 8;
            }
            .addressState {
              flex: 2;
              width: 60px;
            }
          }
        }
      }
    }
  }
}
