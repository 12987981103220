

















































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    min-height: 600px;
    .content {
      max-width: 900px;
      .contentLoaded {
        .body {
          .date {
            max-width: 230px;
          }
        }
      }
    }
  }
}
