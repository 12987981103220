
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    min-height: 1000px;
    .content {
      max-width: 1000px;
      .contentLoaded {
        .imagePreview {
          width: #{$previewCategoryW*$previewCategoryScale}px;
          height: #{$previewCategoryH*$previewCategoryScale}px;
          border-radius: 300px;
          img {
            border-radius: 300px;
          }
        }
        .body {
          .schedulesItens {
            width: 160px;
            display: inline-block;
            margin-right: 30px;
          }

          .schedulesItensTitle {
            width: 100px;
            display: inline-block;
            margin-right: 30px;
          }



          .addressItem {
            margin-left: 12px;
          }

          .addressItemLock {
            margin-left: 12px;

          }
          .addressNumberComplement {
            display: flex;
            .addressNumber {
              flex: 2;
              width: 60px;
            }
            .addressComplement {
              flex: 8;
            }
          }
          .addressCityState {
            display: flex;
            .addressCity {
              flex: 8;
            }
            .addressState {
              flex: 2;
              width: 60px;
            }
          }
        }
      }
    }
  }
}
