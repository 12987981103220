



















































@import '@design';
.box {
  width: 100%;
  background-color: white;
  color: #000000;
  min-height: 672px;

  padding: 144px 0 112px 0;

  @media screen and (max-width: 980px) {
    padding: 29px 8px 0px 8px;
    background-color: $color-bg-mid;
    min-height: initial;
  }

  .container {
    max-width: 1052px;
    margin: 0 auto;
    min-height: 416px;
    background-color: $color-bg-mid;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    @media screen and (max-width: 980px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: initial;
    }

    .contentLeft {
      padding-right: 16px;
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-grow: 1;
      width: 50%;

      @media screen and (max-width: 980px) {
        align-items: center;
        width: initial;
      }

      .bodyLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        max-width: 448px;
        text-align: left;

        @media screen and (max-width: 980px) {
          align-items: center;
          text-align: center;
        }

        .flexibilityTxt1 {
          font-family: $font-montserrat;
          font-size: 32px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -1px;
          color: white;
          @include typography-interpolate(
              'font-size',
              250px,
              19px,
              500px,
              32px
          );
          .learnMore1_2 {
            color: black;
          }
        }
        .flexibilityTxt2 {
          margin-top: 8px;
          font-family: $font-openSans;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: -0.25px;
          color: #1c1c1e;
        }
        .learnMoreButton {
          margin-top: 24px;
          padding: 14px 44px 14px 44px!important;
          height: initial!important;

          font-family: $font-openSans;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.15;
          letter-spacing: -0.25px;
          text-align: center;
          color: #1c1c1e;
        }
      }
    }
    .contentRight {
      width: 50%;
      @media screen and (max-width: 980px) {
        margin-top: 24px;
        max-height: 476px;
        width: initial;
      }
      padding-right: 16px;
      flex-grow: 1;

      .bodyRight {
        width: max-content;
        @media screen and (max-width: 980px) {
          width: fit-content;
        }
        .featuredImage {
          margin-top: -128px;
          margin-bottom: -112px;
          max-height: 656px;
          object-fit: contain;
          @media screen and (max-width: 980px) {
            max-height: 476px;
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
