

















































































@import '@design';

.menuActions {
  display: none;
  @media screen and (max-width: $size-menu-actions+px) {
    display: block;
  }
}
.box {
  /*width: 250px;*/
  /*height: 100%;*/
  text-align: left;
  list-style-type: none;
  padding-inline-start: 0px;

  .bloco {
    padding-bottom: 35px;
    padding-top: 15px;
    padding-left: 20px;
    .title {

    }
    .items {
      list-style-type: none;
      padding-inline-start: 4px;

      .onActive {
        display: none;
        color: $color-menu-active;
      }
      .onInative {
        display: unset;
        color: $color-menu-inactive;
      }

      .router-link-active .onActive {
        display: unset;
      }
      .router-link-active .onInative {
        display: none;
      }
      .item {
        cursor: pointer;
        margin-top: 20px;
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 24px;
        }
      }
    }
  }
}
