















































































@import '@design';
.imgUrl {
  max-width: 110px;
  max-height: 110px;
}
