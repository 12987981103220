





































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    min-height: 600px;
    .content {
      max-width: 600px;
      .contentLoaded {
        .imagePreview {
          width: #{$previewCategoryW*$previewCategoryScale}px;
          height: #{$previewCategoryH*$previewCategoryScale}px;
          border-radius: 300px;
          img {
            border-radius: 300px;
          }
        }
        .body {
          .schedulesItens {
            width: 160px;
            display: inline-block;
            margin-right: 30px;
          }

          .schedulesItensTitle {
            width: 100px;
            display: inline-block;
            margin-right: 30px;
          }

          .itensOp {
            width: 100%;
            max-width: 350px;
            .itemOp {
              margin-left: 13px;
            }

            .itemHead {
              display: flex;
              button {
                align-self: center;
              }
              .field {
                margin-top: 7px;
                margin-bottom: 5px;
              }
            }
            .actions {
              display: flex;
              button {

              }
            }
            .tablesSequence {
              padding: 7px;
              .tablesSequenceBeginEnd {
                display: flex;
                .tablesSequenceEnd {
                  margin-left: 8px;
                }
              }
              .actionsSequence {
                display: flex;
                margin-top: -10px;
                button {

                }
              }
            }
          }
        }
      }
    }
  }
}
