










































































































































































































































































































































































































































































































































































































































@import '@design';

.box {
  @extend %defaultBoxF1;
  .container {
    .content {
      .contentLoaded {
        .imagePreview {
          width: 270px;
          height: 109px;
          img {
          }
        }
        .body {

        }

        .map {
          width: 100%; height: 700px
        }

        .areasContent {
          margin-top: 20px;
          .deleteArea {
            cursor: pointer;
          }
        }
      }
    }
  }
}
