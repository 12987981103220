























.title {
  text-align: center;
  padding: 45px 10px 15px;
  max-width: 800px;
  margin: 0 auto;
}
