



































































































































































































































































































































































































































































































































































































































































































































































































@import '@design';

.fcmEditor {
  @media screen and (max-width: 570px) {
    max-width: 80%;
  }
}

.box {
  @extend %defaultBoxF1;
  .container {
    .content {
      .contentLoaded {
        .imagePreviewContent {
          max-width: #{$previewProductW*$previewProductScale}px;
          .imagePreview {
            width: 100%;
            padding-bottom: #{((100*$previewProductH*$previewProductScale)/($previewProductW*$previewProductScale))+'%'};
            img {
              position:absolute;
            }
          }
        }

        .body {
          .formItemNutritional {
            display: block;
          }
          .nutritionalValue {
            max-width: 50px;
          }
          .variant {
            flex-direction: column;
            padding-left: 20px;
          }
          .variantItems {
            padding-left: 20px;
          }
          .additionalsItem {
            margin-left: 10px;
            margin-top: 4px;
            a {
              color: blue;
            }
          }
          .additionalsItemDisabled {
            margin-left: 10px;
            margin-top: 4px;

            text-decoration: line-through;
            a {
              color: #929292;
            }
          }

          .priceContent {
            display: flex;
            align-items: center;
            .promotion {
              margin-left: 15px;
              color: $color-primary;
            }
          }

        }
      }
    }
  }
}
