











































































































@import '@design';

.error {
  color: $color-input-error;
}
.container {
  min-width: $size-content-width-min;
  max-width: $size-content-width-max;
  margin: 0 auto;
  display: flex;
  min-height: 300px;
  padding-bottom: 30px;
  .content {
    width: 100%;
    padding: 25px 10px 15px;
    text-align: left;
   // background-color: $color-form-bg;
    margin: 0 auto;
 //   max-width: $size-content-width-max;
    .title {
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: -0.4px;
      margin-bottom: 10px;
    }
    .image img {
      border-radius: 8px;
    }
    .body {
      margin-top: 20px;
      font-family: $font-input;
    }
  }
}


