


























































@import '@design';

.menuActionsNot {
  display: block;
  @media screen and (max-width: $size-menu-actions+px) {
    display: none!important;
  }
}

.box {
  background-color: $color-body-bg;
  width: 100%;
  .container {
    padding: 10px;
    height: 118px;
    max-width: $size-content-width-max;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .logo {
      flex: 0;
      display: flex;
      min-width: 220px;
      cursor: pointer;
      img {
        align-self: center;
        max-height: 88px;
        @media screen and (max-width: $size-company-menu-max+px) {
          align-self: flex-start;
          max-height: 52px;
        }
      }
      @media screen and (max-width: $size-company-menu-max+px) {
        min-width: 65px;
      }
    }
    .containerlinks {
      display: flex;
      /*justify-content: flex-end;*/
      div {
        /*justify-content: flex-end;*/
        flex-wrap: wrap;
        align-self: center;
        display: flex;
        div {
          align-self: center;
          /*margin-left: 30px;*/
          BaseButton {

          }
        }
        .itemlink {
          margin-left: 10px;
        }

      }
    }
  }
}


