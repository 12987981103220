







































@import '@design';

html {
  background-color: $color-primary !important;
}

.box {

}

