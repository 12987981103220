






































@import '@design';

.button {
  cursor: pointer;
}

.dialog {
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
}


