// CONTENT
$color-primary: #55C2E6;
$color-body-bg: $color-primary;
$color-text: #2b3342;
$color-heading-text: #2b3342;

// LINKS
$color-link-text: #ffffff;
$color-link-text-active: $color-text;

// INPUTS
$color-input-border: lighten($color-heading-text, 50%);

// BUTTONS
$color-button-bg: $color-link-text;
$color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
$color-button-text: white;


// FCM

$color-footer-bg: #1f2633;
$color-footer-hr: #2b3342;
$color-footer-copyright: #7691c2;
$color-footer-link: #ffffff;

$color-form-bg: white;
$color-input-selected: #2b3341;
$color-input-error: #cf312e;
$color-input-button-primary: #ff3b30;

$color-chamada-txt: white;

$color-menu-active: #55C2E6;
$color-menu-inactive: #A9AAA9;
$color-multiselect-tag-bg: #55C2E6;
$color-multiselect-tag-color: white;

$color-disabled: #a9aaa9;

// Company

$color-company-bg: #ffffff;
$color-ong-bg: #ffffff;

$color-submenu-bg: #ef0f18;
$color-submenu-color: #ffffff;







$color-bg-white: #ffffff;
$color-bg-mid: #4dc8f4;
$color-bg-dark: #263344;




