










































































@import '@design';

.error {
  color: $color-input-error;
}

.box {
  background-color: #ffffff;
  .container {
    min-height: 600px;
    margin: 5px 0px 30px 0px;

    .head {
      text-align: right;
    }
    .tableList {
      text-align: left;
    }
  }
}
