

























































































































@import '@design';

.menuActionsNot {
  display: block;
  @media screen and (max-width: $size-menu-actions+px) {
    display: none!important;
  }
}
.box {
  background-color: $color-body-bg;
  width: 100%;
  .container {
    padding: 10px;
    height: 118px;
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .logo {
      flex: 0;
      display: flex;
      min-width: 220px;
      cursor: pointer;
      img {
        align-self: center;
        max-height: 88px;
        @media screen and (max-width: $size-ong-menu-max+px) {
          align-self: flex-start;
          max-height: 52px;
        }
      }
      @media screen and (max-width: $size-ong-menu-max+px) {
        min-width: 65px;
      }
    }
    .ong {
      @extend %font-navbar;
      flex: 1;
      align-self: center;
      justify-content: flex-start;
      display: flex;
      text-align: start;
      padding-left: 10px;

      .name {
        margin-bottom: 4px;
      }
      .userName {
        @extend %font-content;
        margin-bottom: 10px;
        font-weight: 400;
      }

      img {
        border-radius: 50%;
        align-self: center;
        max-height: 75px;
        flex: 0;
        @media screen and (max-width: $size-ong-menu-max+px) {
          align-self: flex-start;
          max-height: 52px;
        }
      }
      .content {
        align-self: center;
        max-width: 270px;
        padding-left: 10px;
        text-align: start;
        display: flex;
        flex-direction: column;

        .iconsOngStatus {
          .icon {
            width: 35px;
            height: 35px;
            margin-right: 4px;
          }
          .active {
            filter: saturate(20000%) hue-rotate(320deg);
          }
          .config {
            cursor: pointer;
            z-index:100;
          }
          z-index:100;
        }

        .outside {
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0px;
          left: 0px;
          z-index:99;
        }

        .configContainer {
          position: relative;
          .configContent {
            position: absolute;
            z-index:100;
            background-color: white;
            padding: 14px;
            border-radius: 3px;
            min-width: 210px;

            >div {
              margin-bottom: 10px;
            }
          }
        }



      }

      .switch {
        @extend %font-content;
        margin: 1px 5px 5px 0;
      }
    }
    .containerlinks {
      flex: 0 1 auto;
      display: flex;
      /*justify-content: flex-end;*/
      div {
        justify-content: flex-end;
        align-self: center;
        display: flex;
        flex-wrap: wrap;
        div {
          align-self: center;
          /*margin-left: 30px;*/
          BaseButton {

          }
        }
        .itemlink {
          margin-left: 10px;
        }

      }
    }
  }
}


