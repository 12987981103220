






























































































































































































@import '@design';

.error {
  color: $color-input-error;
}

.box {
  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 600px;
    padding: 80px 10px 20px 10px;

    @media screen and (max-width: 980px) {
      flex-direction: column-reverse;
      padding-top: 28px;
    }

    .chamada {
      width: 55%;
      text-align: left;
      color: $color-chamada-txt;
      align-self: center;
      .aumentesuasvendas {
        font-size: 64px;
        @media screen and (max-width: 980px) {
          padding-top: 28px;
        }

        @include typography-interpolate(
            'font-size',
            300px,
            30px,
            1100px,
            64px
        );

        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: -0.5px;
      }
      p {
        max-width: 352px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: -0.8px;
        margin-bottom: 40px;
      }
      .saibamais {
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.15;
        letter-spacing: normal;
      }
    }
    .content {
      width: 95%;
      padding: 49px;
      text-align: left;
      background-color: $color-form-bg;
      align-self: center;
      max-width: 500px;
      .cadastreSeuRestaurante {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
      .jaPossuiConta {
        font-family: $font-secondary-text;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #9b9b9b;

      }
      .criaUmaNovaConta {
        font-family: $font-secondary-text;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-primary;
      }
      .body {
        font-family: $font-input;
        .fullwidth {
          width: 100%;
        }
        BaseButton {
          font-family: $font-primary-text;
        }
      }
    }
  }
}



