






































 @import '@design';

 .box {
   display: flex;
   overflow-y: scroll;
   /*    .left {
         flex: 1 1 0px;
         position: relative;
         max-width: 100%;
       }

       .right {
         flex: 1 1 0px;
         position: relative;
         max-width: 100%;
       } */

/* > div(1) {
   flex: 1 1 0px;
   position: relative;
   //   max-width: 100%;
 }

   > :nth-child(1) {
     flex: 1 1 0px;
     position: relative;
     //   max-width: 100%;
   }
   > :nth-child(0) {
     flex: 1 1 0px;
     position: relative;
     //   max-width: 100%;
   }
   */
   > div {
     flex: 1 1 0px;
     position: relative;
   }
 }



