



































































































































































































































































































































































































































































































@import '@design';

#tab-home {
  height: 300px;
}

.error {
  color: $color-input-error;
}

.box {
  background-color: #ffffff;
  /*min-height: 1000px;*/
  .container {
    /* min-width: $size-content-width-min;
     max-width: $size-content-width-max; */

    min-height: 600px;
    margin: 80px 8px 30px 8px;

    .content {
      /*flex: 1;*/
      /* width: 45%; */
      max-width: 1000px;
      padding: 49px;
      text-align: left;
      background-color: $color-form-bg;
      box-shadow: 2px 2px 15px 5px #888888;

      margin: 0 auto;

      .image {
        @extend %defaultPreview;
        position: relative;
        height: 100px;
        width: 100px;
        cursor: pointer;
        border-radius: 300px;
        img {
          height: 100px;
          width: 100px;
          margin-bottom: 20px;
          border-radius: 300px;
        }
        .changeImage {
          position: absolute;
          left: 0;
          top: 0;
          width: 100px;
          margin-top: 60px;
          color: white;
          background-color: rgba(0, 0, 0, 0.71);
          text-align: center;
          display: none;
        }
      }
      .image:hover .changeImage {
        display: block;
      }


      .restaurantName {
        font-size: 32px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #333333;
        margin-bottom: 20px;
      }
      .info {
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 3.2;
        letter-spacing: 0.3px;
        color: #707070;
      }
      .body {
        font-family: $font-input;
        BaseButton {
          font-family: $font-primary-text;
          float: right;
        }

        .progressBar {
          margin-bottom: 20px;
        }
      }
    }
  }
}



