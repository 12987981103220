













































































@import '@design';

.list {
  // box-shadow: 6px 2px 4px -1px rgba(0,0,0,.2), 6px 4px 7px 0 rgba(0,0,0,.14), 6px 1px 12px 0 rgba(0,0,0,.12);

  /*    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2);
      box-shadow: 0 5px 8px 0 rgba(0,0,0,.14);
      box-shadow: 0 1px 14px 0 rgba(0,0,0,.12); */
  background-color: #f4f6fa;
}
.box {
  background-color: #ffffff;
  .container {
    min-height: 600px;
    margin: 5px 0px 30px 0px;

    .head {
      text-align: right;
    }
    .tableList {
      text-align: left;
    }
  }
}

