














































































@import '@design';

.box {
  .container {
    cursor: pointer;
    display: flex;
    .colorBg {
      width: 30px;
      height: 30px;

    }
    .text {
      margin-left: 10px;
    }
  }
}

.modal {
  min-width: 220px!important;
}
